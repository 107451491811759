<template>
  <van-tabs v-model="active" @change="switchTab">
    <van-tab>
      <template #title>
        <span :class="active===0?'dd':''">全部记录</span>
      </template>
      <div class="content">
        <van-empty v-if="!show" description="暂无记录" />
        <van-list
          v-else
          :immediate-check="false"
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            v-for="item in data"
            :key="item.id"
            class="bottom"
            @click="goBookingdetails(item.id)"
          >
            <div class="bottom-top">
              <div>
                订单编号：
                <span>{{item.id}}</span>
              </div>
              <div
                class="sta"
                :id="item.orderStatus===0?'':item.orderStatus===2?'orange':item.orderStatus===1?'':'black'"
              >{{item.orderStatus===0?'待审核':item.orderStatus===2?'已审核通过':item.orderStatus===1?'待审核':'预约失败'}}</div>
            </div>
            <div class="bottom-bom">
              <img :src="item.areaImg" alt />
              <div>
                <div class="bottom-bom-name">{{item.areaTitle}}</div>
                <span v-if="item.measureArea">{{item.measureArea}}m²，可容纳{{item.capacity}}人</span>
                <div class="bottom-bom-time">预约时间段：</div>
                <div>{{item.applyTime}} {{item.a+'-'+item.b}}</div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </van-tab>
    <van-tab>
      <template #title>
        <span :class="active===1?'dd':''">待审核</span>
      </template>
      <div class="content">
        <van-empty v-if="!show1" description="暂无记录" />
        <van-list
          v-else
          :immediate-check="false"
          v-model="loading1"
          :finished="finished1"
          finished-text="没有更多了"
          @load="onLoad1"
        >
          <div
            v-for="item in data"
            :key="item.id"
            class="bottom"
            @click="goBookingdetails(item.id)"
          >
            <div class="bottom-top">
              <div>
                订单编号：
                <span>{{item.id}}</span>
              </div>
              <div
                :id="item.orderStatus===0?'':item.orderStatus===2?'orange':item.orderStatus===1?'':'black'"
                class="sta"
              >{{item.orderStatus===0?'待审核':item.orderStatus===2?'已审核通过':item.orderStatus===1?'待审核':'预约失败'}}</div>
            </div>
            <div class="bottom-bom">
              <img :src="item.areaImg" alt />
              <div>
                <div class="bottom-bom-name">{{item.areaTitle}}</div>
                <span v-if="item.measureArea">{{item.measureArea}}m²，可容纳{{item.capacity}}人</span>
                <div class="bottom-bom-time">预约时间段：</div>
                <div>{{item.applyTime}} {{item.a+'-'+item.b}}</div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </van-tab>
    <van-tab>
      <template #title>
        <span :class="active===2?'dd':''">已审核</span>
      </template>
      <div class="content">
        <van-empty v-if="!show2" description="暂无记录" />
        <van-list
          v-else
          :immediate-check="false"
          v-model="loading2"
          :finished="finished2"
          finished-text="没有更多了"
          @load="onLoad2"
        >
          <div
            v-for="item in data"
            :key="item.id"
            class="bottom"
            @click="goBookingdetails(item.id)"
          >
            <div class="bottom-top">
              <div>
                订单编号：
                <span>{{item.id}}</span>
              </div>
              <div
                :id="item.orderStatus===0?'':item.orderStatus===2?'orange':item.orderStatus===1?'':'black'"
                class="sta"
              >{{item.orderStatus===0?'待审核':item.orderStatus===2?'已审核通过':item.orderStatus===1?'待审核':'预约失败'}}</div>
            </div>
            <div class="bottom-bom">
              <img :src="item.areaImg" alt />
              <div>
                <div class="bottom-bom-name">{{item.areaTitle}}</div>
                <span v-if="item.measureArea">{{item.measureArea}}m²，可容纳{{item.capacity}}人</span>
                <div class="bottom-bom-time">预约时间段：</div>
                <div>{{item.applyTime}} {{item.a+'-'+item.b}}</div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </van-tab>
  </van-tabs>
</template>

<script>
// import unreviewed from './unreviewed.vue'
import { getSubscribePage, getSubscribeReviewPage } from '@/api/user'

export default {
  // components: {
  //   unreviewed
  // },
  data() {
    return {
      show: true,
      show2: true,
      show1: true,
      loading: false,
      finished: false,
      loading1: false,
      finished1: false,
      loading2: false,
      finished2: false,
      active: 0,
      data: [],
      flag: 0,
      num: 1,
      num1: 1,
      num2: 1
    }
  },
  created() {
    this.flag = this.$route.query.isReviewer
    if (this.flag) {
      this.getSubscribeReviewPage()
    } else {
      this.getSubscribePage()
    }
  },
  methods: {
    goBookingdetails(id) {
      this.$router.push(`/bookingdetails?id=${id}&isReviewer=${this.flag}`)
    },
    async onLoad() {
      this.num += 1
      console.log('触底', this.num)
      if (this.flag) {
        const { data } = await getSubscribeReviewPage({
          pageSize: 10,
          pageNum: this.num
        })
        data.data.records.forEach(res => {
          res.applyTime = res.startTime.substring(0, 10)
          res.a = res.startTime.substring(11).substring(0, 5)
          res.b = res.endTime.substring(11).substring(0, 5)
        })
        if (data.data.records.length !== 0) {
          this.data = [...this.data, ...data.data.records]
          this.loading = false
        } else {
          this.finished = true
        }
      } else {
        const { data } = await getSubscribePage({
          pageSize: 10,
          pageNum: this.num
        })
        data.data.records.forEach(res => {
          res.applyTime = res.startTime.substring(0, 10)
          res.a = res.startTime.substring(11).substring(0, 5)
          res.b = res.endTime.substring(11).substring(0, 5)
        })
        if (data.data.records.length !== 0) {
          this.data = [...this.data, ...data.data.records]
          this.loading = false
        } else {
          this.finished = true
        }
      }
    },
    async onLoad1() {
      this.num1 += 1
      console.log('触底', this.num1)
      if (this.flag) {
        const { data } = await getSubscribeReviewPage({
          pageSize: 10,
          pageNum: this.num1,
          orderStatus: 1
        })
        data.data.records.forEach(res => {
          res.applyTime = res.startTime.substring(0, 10)
          res.a = res.startTime.substring(11).substring(0, 5)
          res.b = res.endTime.substring(11).substring(0, 5)
        })
        if (data.data.records.length !== 0) {
          this.data = [...this.data, ...data.data.records]
          this.loading1 = false
        } else {
          this.finished1 = true
        }
      } else {
        const { data } = await getSubscribePage({
          pageSize: 10,
          pageNum: this.num1,
          orderStatus: 1
        })
        data.data.records.forEach(res => {
          res.applyTime = res.startTime.substring(0, 10)
          res.a = res.startTime.substring(11).substring(0, 5)
          res.b = res.endTime.substring(11).substring(0, 5)
        })
        if (data.data.records.length !== 0) {
          this.data = [...this.data, ...data.data.records]
          this.loading1 = false
        } else {
          this.finished1 = true
        }
      }
    },
    async onLoad2() {
      this.num2 += 1
      console.log('触底', this.num2)
      if (this.flag) {
        const { data } = await getSubscribeReviewPage({
          pageSize: 10,
          pageNum: this.num2,
          orderStatus: 2
        })
        data.data.records.forEach(res => {
          res.applyTime = res.startTime.substring(0, 10)
          res.a = res.startTime.substring(11).substring(0, 5)
          res.b = res.endTime.substring(11).substring(0, 5)
        })
        if (data.data.records.length !== 0) {
          this.data = [...this.data, ...data.data.records]
          this.loading2 = false
        } else {
          this.finished2 = true
        }
      } else {
        const { data } = await getSubscribePage({
          pageSize: 10,
          pageNum: this.num2,
          orderStatus: 1
        })
        data.data.records.forEach(res => {
          res.applyTime = res.startTime.substring(0, 10)
          res.a = res.startTime.substring(11).substring(0, 5)
          res.b = res.endTime.substring(11).substring(0, 5)
        })
        if (data.data.records.length !== 0) {
          this.data = [...this.data, ...data.data.records]
          this.loading2 = false
        } else {
          this.finished2 = true
        }
      }
    },
    switchTab(row) {
      this.data = []
      this.num = 1
      this.num1 = 1
      this.num2 = 1
      if (this.flag) {
        this.getSubscribeReviewPage(row)
      } else {
        this.getSubscribePage(row)
      }
    },
    async getSubscribePage(a = 0) {
      const { data } = await getSubscribePage({
        orderStatus: a,
        pageSize: 10,
        pageNum: 1
      })
      console.log(data)
      data.data.records.forEach(res => {
        res.applyTime = res.startTime.substring(0, 10)
        res.a = res.startTime.substring(11).substring(0, 5)
        res.b = res.endTime.substring(11).substring(0, 5)
      })
      this.data = data.data.records
      if (this.data.length === 0) {
        this.show = false
        this.show1 = false
        this.show2 = false
      } else {
        this.show = true
        this.show1 = true
        this.show2 = true
      }
    },
    async getSubscribeReviewPage(a = 0) {
      const { data } = await getSubscribeReviewPage({
        orderStatus: a,
        pageSize: 10,
        pageNum: 1
      })
      data.data.records.forEach(res => {
        res.applyTime = res.startTime.substring(0, 10)
        res.a = res.startTime.substring(11).substring(0, 5)
        res.b = res.endTime.substring(11).substring(0, 5)
      })
      console.log(data)
      this.data = data.data.records
      if (this.data.length === 0) {
        this.show = false
        this.show1 = false
        this.show2 = false
      } else {
        this.show = true
        this.show1 = true
        this.show2 = true
      }
    }
  }
}
</script>

<style lang="less" scoped>
.van-empty {
  margin-top: 20vh;
}
#orange {
  color: #f8892f;
}
#black {
  color: #9d9d9d;
}
/deep/ .van-tabs__content {
  padding-bottom: 65px;
  padding-top: 44px;
}
/deep/ .dd {
  font-size: 15px !important;
  color: #f8892f;
}
/deep/ .van-tabs__line {
  background-color: #f8892f;
  width: 30px;
}
/deep/ .van-tabs__wrap {
  position: fixed;
  width: 100vw;
  top: 0;
  z-index: 2;
}
.content {
  background-color: #f9f9f9;
  // height: 100vh;
  width: 100%;
  .bottom {
    box-shadow: 0 0 4px #cccccc;
    margin-top: 15px;
    height: 150px;
    font-size: 14px;
    width: 94%;
    margin-left: 3%;
    background-color: #fff;
    padding: 9px 10px;
    box-sizing: border-box;
    border-radius: 9px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .bottom-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .sta {
        color: #2f92f8;
      }
      .sta2 {
        color: #f8892f;
      }
    }
    .bottom-bom {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #999;
      .bottom-bom-name {
        font-size: 16px;
        color: #333333;
        margin-top: 5px;
        margin-bottom: 8px;
      }
      .bottom-bom-time {
        color: #333;
        margin-top: 12px;
      }
    }
    img {
      margin-right: 12px;
      width: 130px;
      height: 100px;
    }
  }
}
</style>
